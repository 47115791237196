import React, { Component } from 'react';
export default class ContactUs extends Component {
  render() {
    return (
      <section id="contact">
          <div className="row">
            <aside className="eigth columns footer-widgets">
              <div className="widget">
                <h1>Contact Info</h1>
                <h4>Email: kkcarter1129@gmail.com</h4>
                <h4>Phone: (972) 832-6437</h4>
                <h4>Linked in: <a href="https://www.linkedin.com/in/kylekcarter/" rel='noreferrer' target='_blank'>Kyle Carter</a></h4>
                <h4>GitHub: <a href="https://github.com/KyleKCarter" rel='noreferrer'>Kyle Carter</a></h4>
              </div>
            </aside>
          </div>
        </section>
        );
  }
}